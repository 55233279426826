import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Irrigation Engineering`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1P8c6_H0z1xhZiOkDmdp7EmIUDwrc7SL3"
      }}>{`Download: Irrigation and Hydraulic Structures Theory, Design & Practice By Dr. Iqbal Ali`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1QXbLAjrJU6v29RgiIrppelZ3UVaiTjh2"
      }}>{`Download: Hydraulic Structures 4th ed by P. Novak, A.I.B Moffat, C. Nullari, R. Narayanan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1kbrcYtMgKRJR99h5sg6ve2ApBSvOLveX"
      }}>{`Download: Irrigation engineering and hydraulic structures by Santosh Kumar Garg`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1n488wm06WVznl5HJNt87U3UgJSgRB-PP"
      }}>{`Download: Irrigation engineering by NN Basak`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=19GpOyDMVIIltR8bg2mtuSJ1OhGbme0vG"
      }}>{`Download: Irrigation and water resources engineering by G.L. Asawa`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1U5qDFjOtFVJ9s47xTa0J8vrY9OCxJYco"
      }}>{`Download: FAO Irrigation and drainage paper no. 56 Crop evapotranspiration`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1WecYTxUUpTAoOudmsudd9r4pclZ8PU2T"
      }}>{`Download: FAO Irrigation and drainage paper no. 24 Crop water requirements`}</a></p>
    <h2>{`Lab reports`}</h2>
    <h3>{`Job 1: Indus Basin Irrigation System`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Indus-Basin-Irrigation-System-and-it-various-Salient-Features-Barrages-Headwork-Canals-and-Dams1.pdf"
      }}>{`Indus Basin Irrigation System and it various Salient Features (Barrages, Headwork, Canals and Dams)1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Indus-Basin-Irrigation-System-and-it-various-Salient-Features-Barrages-Headwork-Canals-and-Dams2.pdf"
      }}>{`Indus Basin Irrigation System and it various Salient Features (Barrages, Headwork, Canals and Dams)2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Indus-Basin-Irrigation-System-and-it-various-Salient-Features-Barrages-Headwork-Canals-and-Dams3.pdf"
      }}>{`Indus Basin Irrigation System and it various Salient Features (Barrages, Headwork, Canals and Dams)3`}</a></p>
    <h3>{`Job 2: Design of irrigation system`}</h3>
    <p>{`Instructions:`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/lab-2.Design-of-Irrigation-System.pdf"
      }}>{`lab 2.Design of Irrigation System`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/lab-345.Design-of-Irrigation-System-Part-2.pdf"
      }}>{`lab 3,4,5.Design of Irrigation System (Part 2)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Research-Report-Design-and-Practice-of-Water-Allocation-Rules-Lessons-from-Warabandi-in-Pakistans-Punjab-International-Irrigation-Management-Institute-1998.pdf"
      }}>{`Research Report – Design and Practice of Water Allocation Rules – Lessons from Warabandi in Pakistans Punjab – International Irrigation Management Institute – 1998`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/warabandi-in-pakistans-irrigation-canal-system.pdf"
      }}>{`Warabandi in Pakistan’s irrigation canal system`}</a></p>
    <p>{`Design reports`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/canal-design.pdf"
      }}>{`Canal design report`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Canal-design.xlsx"
      }}>{`Canal design Excel sheet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/design-of-canal-by-kennedy-regime-theory.xlsx"
      }}>{`Design of canal by Kennedy regime theory excel sheet`}</a></p>
    <h3>{`Job 3: Design of barrage`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/barrage-design1.xlsx"
      }}>{`Barrage design excel sheet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Cross-section-of-barrage.pdf"
      }}>{`Cross section of barrage`}</a></p>
    <h2>{`Lecture slides`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/1-Introduction.pptx"
      }}>{`1-Introduction to irrigation engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/2._Crop_water_requirements-2013.ppt"
      }}>{`2._Crop_water_requirements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/3.Earthen-Channel-Design.pptx"
      }}>{`3.Earthen Channel Design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/4.Water_resources_in_Pakistan.ppt"
      }}>{`4.Water_resources_in_Pakistan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/5._Canal_Lining.ppt"
      }}>{`5._Canal_Lining`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/6._Maintenance_of_irrigation_canals.ppt"
      }}>{`6._Maintenance_of_irrigation_canals`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/7.Canal_Outlets.ppt"
      }}>{`7. Canal_Outlets`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/8._Methods_of_irrigation.ppt"
      }}>{`8._Methods_of_irrigation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/9.-Silt-Control-in-Irrigation-Channels.pptx"
      }}>{`9. Silt Control in Irrigation Channels`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/10._Canal_Falls.pdf"
      }}>{`10._Canal_Falls`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/11.water_logging_salinity.pptx"
      }}>{`11.Water_logging_salinity`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/12.-Regulators.pptx"
      }}>{`12. Regulators`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/13.X-drainage-works.pptx"
      }}>{`13.X-drainage works`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/14.wells_.pptx"
      }}>{`14. Wells`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/15.warabandi.ppt"
      }}>{`15. Warabandi`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Indus-Waters-Treaty-1960.pdf"
      }}>{`Indus Waters Treaty 1960`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      